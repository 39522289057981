import {
  CogIcon,
  CreditCardIcon,
  DiscordIcon,
  ExitIcon,
  HeartIcon,
} from '~/components/icons';
import type { ButtonUnionProps, DropdownProps } from '~/components/ui';
import { Role, type AuthUser } from '~/modules/api';
import { isEditor, isMember, link } from '~/utils';

interface NavItem {
  href: string;
  label: string;
  variant?: ButtonUnionProps['variant'];
  trackable?: boolean;
}

export const getHeaderNavItems = (user?: AuthUser | null): NavItem[] => [
  {
    href: link.toFreeThisWeek(),
    label: 'Free This Week',
  },
  {
    href: link.toCategory({ category: 'top_10' }),
    label: 'Top 10 Shows',
  },
  {
    href: link.toCategory({ category: 'trending' }),
    label: 'Popular Now',
  },
  ...(user
    ? []
    : [
        {
          href: link.toMembershipPage(),
          label: 'Get Premium',
          trackable: true,
        },
      ]),
];

export const getLoggedOutMenuItems = (): NavItem[] => [
  {
    href: link.toSignInFlow(),
    label: 'Log In',
    variant: 'blur',
  },
  {
    href: link.toSignUpFlow(),
    label: 'Sign Up',
    variant: 'primary',
    trackable: true,
  },
];

export const getLoggedInMenuItems = (
  user?: AuthUser,
): { buttons: NavItem[]; dropdown: DropdownProps['options'] } => {
  const dropdown = [
    {
      prefix: <HeartIcon />,
      label: 'Favorites',
      url: link.toMyAccount({ tab: 'favorites' }),
    },
    {
      prefix: <DiscordIcon class="size-5" />,
      label: 'Join the Discord',
      url: link.toDiscordChannel(),
    },
    ...(isMember(user ? user.roles : []) && !isEditor(user ? user.roles : [])
      ? [
          {
            prefix: <CreditCardIcon />,
            label: 'Manage Membership',
            url: link.toBilling(),
          },
        ]
      : []),

    ...(isEditor(user ? user.roles : [])
      ? [
          {
            prefix: <CogIcon />,
            label: 'Content Validator',
            url: link.toContentValidator(),
          },
        ]
      : []),
    {
      prefix: <ExitIcon />,
      label: 'Logout',
      url: link.toLogOut(),
    },
  ];

  return {
    buttons: user?.roles.includes(Role.MEMBER)
      ? []
      : [
          {
            href: link.toMembershipPage(),
            label: 'Get Premium',
            variant: 'primary',
            trackable: true,
          },
        ],
    dropdown,
  };
};
