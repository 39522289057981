import {cn} from '~/utils';
import type {IconProps} from '../types';

export function ExitIcon(props: IconProps) {
  return (
    <svg
      class={cn('size-5', props.class)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.5C7 13.6326 6.94732 13.7598 6.85355 13.8536C6.75979 13.9473 6.63261 14 6.5 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H6.5C6.63261 2 6.75979 2.05268 6.85355 2.14645C6.94732 2.24021 7 2.36739 7 2.5C7 2.63261 6.94732 2.75979 6.85355 2.85355C6.75979 2.94732 6.63261 3 6.5 3H3V13H6.5C6.63261 13 6.75979 13.0527 6.85355 13.1464C6.94732 13.2402 7 13.3674 7 13.5ZM13.8538 7.64625L11.3538 5.14625C11.2838 5.07624 11.1947 5.02856 11.0977 5.00924C11.0006 4.98991 10.9 4.99981 10.8086 5.03769C10.7172 5.07556 10.6391 5.13971 10.5841 5.22201C10.5292 5.30431 10.4999 5.40105 10.5 5.5V7.5H6.5C6.36739 7.5 6.24021 7.55268 6.14645 7.64645C6.05268 7.74021 6 7.86739 6 8C6 8.13261 6.05268 8.25979 6.14645 8.35355C6.24021 8.44732 6.36739 8.5 6.5 8.5H10.5V10.5C10.4999 10.5989 10.5292 10.6957 10.5841 10.778C10.6391 10.8603 10.7172 10.9244 10.8086 10.9623C10.9 11.0002 11.0006 11.0101 11.0977 10.9908C11.1947 10.9714 11.2838 10.9238 11.3538 10.8538L13.8538 8.35375C13.9002 8.30731 13.9371 8.25217 13.9623 8.19147C13.9874 8.13077 14.0004 8.06571 14.0004 8C14.0004 7.93429 13.9874 7.86923 13.9623 7.80853C13.9371 7.74783 13.9002 7.69269 13.8538 7.64625Z"
        fill="currentColor"
      />
    </svg>
  );
}
