import {cn} from '~/utils';
import type {IconProps} from '../types';

export function HeartIcon(props: IconProps) {
  return (
    <svg
      class={cn('size-5', props.class)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6.125C15.0011 6.60115 14.9077 7.07279 14.7253 7.51264C14.543 7.95249 14.2752 8.3518 13.9375 8.6875L8.35625 14.3512C8.30973 14.3985 8.25427 14.436 8.19311 14.4616C8.13195 14.4872 8.06631 14.5004 8 14.5004C7.9337 14.5004 7.86806 14.4872 7.80689 14.4616C7.74573 14.436 7.69028 14.3985 7.64375 14.3512L2.0625 8.6875C1.38206 8.00788 0.999458 7.08579 0.998872 6.12408C0.998285 5.16237 1.37976 4.23982 2.05938 3.55938C2.739 2.87893 3.66108 2.49633 4.62279 2.49574C5.5845 2.49516 6.50706 2.87663 7.1875 3.55625L8 4.31563L8.81813 3.55375C9.32591 3.04849 9.972 2.705 10.6748 2.56664C11.3777 2.42829 12.1057 2.50126 12.7671 2.77636C13.4285 3.05146 13.9936 3.51634 14.391 4.11233C14.7884 4.70831 15.0003 5.40867 15 6.125Z"
        fill="currentColor"
      />
    </svg>
  );
}
