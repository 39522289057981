import { createSignal, For, onMount } from 'solid-js';
import { DreamFlareWordmarkIcon } from '~/components/icons';
import { UserAccountMenu } from '~/components/shared/UserAccountMenu';
import { Button, Skeleton } from '~/components/ui';
import { getHeaderNavItems } from '~/data/header';
import type { AuthUser } from '~/modules/api';
import { cn } from '~/utils';

export interface HeaderProps {
  user?: AuthUser;
  options?: {
    hideStickyNavOnScrollDown?: boolean;
  };
}

export function Header(props: HeaderProps) {
  const [lastScrollPos, setLastScrollPos] = createSignal(0);
  const user = props.user;
  let headerRef: HTMLDivElement | undefined;
  let navRef: HTMLDivElement | undefined;

  const fixNavbarToTop = () => {
    if (!navRef?.classList.contains('fixed')) {
      navRef?.classList.add('fixed');
      if (headerRef) {
        headerRef.style.marginBottom = `${navRef?.clientHeight}px`;
      }
    }
  };

  const resetNavbarPos = () => {
    if (navRef?.classList.contains('fixed')) {
      navRef.classList.remove('fixed');
      if (headerRef) {
        headerRef.style.marginBottom = `0px`;
      }
    }
  };

  const updateNavbarVisibility = (
    isScrollDirectionUp: boolean,
    mainHeaderIsOffScreen: boolean,
  ) => {
    if (!navRef) return;
    navRef.style.opacity =
      isScrollDirectionUp || !mainHeaderIsOffScreen ? '1' : '0';
    navRef.style.pointerEvents =
      isScrollDirectionUp || !mainHeaderIsOffScreen ? 'auto' : 'none';
  };

  const positionNavBar = () => {
    if (!headerRef || !navRef) return;

    const currentScrollPos = window.scrollY;
    const mainHeaderIsOffScreen = currentScrollPos >= headerRef?.clientHeight;
    const isScrollDirectionUp = currentScrollPos <= lastScrollPos();
    setLastScrollPos(currentScrollPos);

    if (props.options?.hideStickyNavOnScrollDown) {
      updateNavbarVisibility(isScrollDirectionUp, mainHeaderIsOffScreen);
    }

    if (mainHeaderIsOffScreen) {
      return fixNavbarToTop();
    }
    resetNavbarPos();
  };

  onMount(() => {
    positionNavBar();
    window.addEventListener('scroll', positionNavBar);
  });

  return (
    <header class="w-full z-10 dark bg-black">
      <div
        ref={headerRef}
        class="relative px-4 flex justify-between h-12 md:h-16 items-center
          mx-auto max-w-screen-2xl rounded-xl"
      >
        <div class="flex gap-x-10 items-center">
          <a
            href="/"
            class="text-2xl font-bold text-white relative"
            aria-label="DreamFlare"
          >
            <img
              src="/logos/beta.png"
              alt="Beta badge"
              class="absolute right-0 w-5 -top-1.5"
            />
            <DreamFlareWordmarkIcon class="w-32 md:w-44" />
          </a>

          <NavItems user={user} class="hidden lg:flex" />
        </div>

        <UserAccountMenu user={user} />
      </div>
      <NavItems
        ref={navRef}
        user={user}
        class="lg:hidden top-0 z-30 px-4 w-full bg-black transition-opacity"
      />
    </header>
  );
}

export function HeaderSkeleton() {
  return <Skeleton class="w-full h-24 md:h-28 lg:h-16 rounded-none" />;
}

function NavItems(props: { user?: AuthUser; class: string; ref?: any }) {
  return (
    <nav
      ref={props.ref}
      class={cn(
        `flex justify-start gap-x-4 sm:gap-x-6 items-center overflow-x-scroll
        md:overflow-x-hidden`,
        props.class,
      )}
    >
      <For each={getHeaderNavItems(props.user)}>
        {({ href, label, trackable }) => (
          <Button
            href={href}
            variant="link"
            size="sm"
            trackable={trackable}
            class="font-semibold px-0 h-12 whitespace-nowrap"
          >
            {label}
          </Button>
        )}
      </For>
    </nav>
  );
}
